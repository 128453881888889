<template>
  <div class="perfect-big">
    <!-- <icy-header title="个人信息"></icy-header> -->

    <!-- 头像 -->
    <!-- 此处 font-size: 0 是为了 取消 van-uploader 的 display:inline-block 产生的间隙 -->
    <div style="font-size: 0">
      <van-uploader :after-read="afterRead" :deletable="false" :max-count="1">
        <div class="portrait-box dfr cross-center" slot="default">
          <div class="fs28 fw400 fc333 dfr">头像</div>
          <div class="portrait dfr">
            <img
              v-if="defaultAvatar"
              :src="defaultAvatar"
              alt=""
              class="w100 h100 object-cover"
            />
            <img
              v-else
              src="../assets/images/default-portrait.png"
              alt=""
              class="w100 h100"
            />
          </div>
          <div class="arrow-box dfr">
            <van-icon name="arrow" size="0.2rem" color="#999999" />
          </div>
        </div>
      </van-uploader>
    </div>

    <!-- 姓名 -->
    <div class="general-box general-box-bottom dfr cross-center">
      <div class="fs28 fw400 fc333 dfr">姓名</div>
      <van-field
        v-model="nameText"
        input-align="right"
        autosize
        placeholder="未填写"
        class="input-box dfr cross-center"
      />
    </div>

    <!-- 所属公司 -->
    <div class="general-box general-box-bottom dfr cross-center">
      <div class="fs28 fw400 fc333 dfr">所属公司</div>
      <van-field
        v-model="companyText"
        input-align="right"
        autosize
        placeholder="未填写"
        class="input-box dfr cross-center"
      />
    </div>

    <!-- 公司规模 -->
    <div
      class="general-box general-box-bottom dfr cross-center"
      @click="scaleIsShow = true"
    >
      <div class="fs28 fw400 fc333 dfr">公司规模</div>
      <div
        :v-model="scaleText"
        class="choice-box fs28 fw400 dfr cross-center main-end"
      >
        <div v-if="!scaleText" class="fcccc">请选择</div>
        <div v-else>{{ scaleText }}</div>
      </div>
      <div class="arrow-box dfr">
        <van-icon name="arrow" size="0.2rem" color="#999999" />
      </div>
    </div>
    <van-popup v-model="scaleIsShow" position="bottom" style="height: 3.9rem">
      <van-picker
        show-toolbar
        title=""
        :columns="scaleList"
        :default-index="scaleDefaultIndex"
        @confirm="onScaleConfirm"
        @cancel="scaleIsShow = false"
        :visible-item-count="3"
      >
        <icy-button
          width="1.2rem"
          height=".6rem"
          color="#fff"
          fontSize=".24rem"
          slot="confirm"
          background="#000000"
          >确定</icy-button
        >
        <van-icon slot="cancel" class="fc000 fs32" name="arrow-down" />
      </van-picker>
    </van-popup>

    <!-- 公司行业 -->
    <div
      class="general-box general-box-bottom dfr cross-center"
      @click="industryIsShow = true"
    >
      <div class="fs28 fw400 fc333 dfr">公司行业</div>
      <div
        :v-model="industryText"
        class="choice-box fs28 fw400 dfr cross-center main-end"
      >
        <div v-if="!industryText" class="fcccc">请选择</div>
        <div v-else>{{ industryText }}</div>
      </div>
      <div class="arrow-box dfr">
        <van-icon name="arrow" size="0.2rem" color="#999999" />
      </div>
    </div>
    <van-popup
      v-model="industryIsShow"
      position="bottom"
      style="height: 3.9rem"
    >
      <van-picker
        show-toolbar
        title=""
        :columns="industryList"
        :default-index="industryDefaultIndex"
        @confirm="onIndustryConfirm"
        @cancel="industryIsShow = false"
        :visible-item-count="3"
      >
        <icy-button
          width="1.2rem"
          height=".6rem"
          color="#fff"
          fontSize=".24rem"
          slot="confirm"
          background="#000000"
          >确定</icy-button
        >
        <van-icon slot="cancel" class="fc000 fs32" name="arrow-down" />
      </van-picker>
    </van-popup>

    <!-- 担任职务 -->
    <div class="general-box general-box-bottom dfr cross-center">
      <div class="fs28 fw400 fc333 dfr">担任职务</div>
      <van-field
        v-model="positionText"
        input-align="right"
        autosize
        placeholder="未填写"
        class="input-box dfr cross-center"
      />
    </div>

    <!-- 主持风格 -->
    <div v-if="type && type == 1">
      <div class="general-box dfr cross-center">
        <div class="fs28 fw400 fc333 dfr">主持风格</div>
        <van-field
          v-model="styleText"
          input-align="right"
          autosize
          placeholder="如 '温柔一刀' '犀利直接'"
          class="input-box dfr cross-center"
        />
      </div>
    </div>

    <div class="bg-color" />

    <!-- 擅长领域 -->
    <div class="field-box">
      <div class="dfr cross-center">
        <div class="title-left"><app-title title="擅长领域" /></div>
        <div class="doux fs24 fw500 fcb2">(多选)</div>
      </div>
      <div class="field-list-box dfr flex-wrap">
        <div
          class="field-list-un fs26 fw400 fc666 dfr flex-center"
          :class="{ 'field-list-on': item.active }"
          v-for="(item, index) in fieldList"
          :key="index"
          @click="btnActive(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 个人简介信息 -->
    <div>
      <div class="intro-box">
        <app-title title="个人简介信息" />

        <!-- 弹出层 -->
        <van-dialog
          v-model="introShow"
          showCancelButton
          @confirm="introConfirm"
          confirmButtonColor="#cb9025"
        >
          <van-field
            v-model="introDialogText"
            type="textarea"
            :autosize="{ maxHeight: 230, minHeight: 230 }"
            placeholder="请填写个人简介信息"
          />
        </van-dialog>
        <div v-if="description.length">
          <div v-for="(item, index) in description" :key="index">
            <div>
              <text-content
                v-if="item.type == 0"
                :introText="item.text"
                :index="index"
                @deleteIntroText="deleteIntroText(index)"
                @changeCate="changeCate"
              ></text-content>

              <img-content
                v-else
                :introImg="item.image"
                @deleteIntroImg="deleteIntroImg(index)"
              ></img-content>
            </div>
          </div>
        </div>
      </div>
      <!-- 个人简介信息按钮 -->
      <div class="dfr main-between" style="margin: 0 0.36rem 1.5rem 0.36rem">
        <icy-button
          width="3rem"
          height="0.8rem"
          background="#f6f6f6"
          border-radius="0.1rem"
          boxshadow="0"
          color="#000"
          @click.native="addText"
          >追加文字段</icy-button
        >
        <van-uploader
          :after-read="afterReadAdd"
          :deletable="false"
          :max-count="10"
          multiple
        >
          <icy-button
            width="3rem"
            height="0.8rem"
            background="#f6f6f6"
            border-radius="0.1rem"
            boxshadow="0"
            color="#000"
            slot="default"
            >追加图片</icy-button
          >
        </van-uploader>
      </div>
    </div>

    <!-- 保存按钮 -->
    <!-- :class="[type == 1 ? 'host-mauto-box' : 'mauto-box']" -->
    <div
      class="dfc flex-center"
      :class="[type == 1 ? 'host-mauto-box' : 'mauto-box']"
      @click="savePerfect"
      v-show="hideshow"
    >
      <icy-button width="6.78rem" height="0.8rem">保存</icy-button>
    </div>
  </div>
</template>

<script>
import { uploadImg } from "../utils/util";
export default {
  data() {
    return {
      // 用户头像
      defaultAvatar: "",
      // 姓名
      nameText: "",
      // 所属公司
      companyText: "",
      // 公司规模弹出层是否展示
      scaleIsShow: false,
      // 公司规模
      scaleText: "",
      // 公司规模id
      scaleId: "",
      // 公司规模id数组
      scaleIds: [],
      // 公司规模默认选中项的索引
      scaleDefaultIndex: 1,
      // 公司规模列表
      scaleList: [],
      // 公司行业弹出层是否展示
      industryIsShow: false,
      // 公司行业
      industryText: "",
      // 公司行业id
      industryId: "",
      // 公司行业id数组
      industryIds: [],
      // 公司行业默认选中项的索引
      industryDefaultIndex: 1,
      // 公司行业列表
      industryList: [],
      // 担任职务
      positionText: "",
      // 接收到的type
      type: "",
      // 主持风格
      styleText: "",
      // 个人简介信息
      introDialogText: "",
      introShow: false,
      description: [],
      // 擅长领域数组
      fieldList: [],
      //实时屏幕高度
      showHeight: document.documentElement.clientHeight,
      //保存按钮显示或者隐藏
      hideshow: true,
      // TODO
      meetingId: "",
      userId: "",
      backStatus: "", // 1 首页  2我的   3invite  4填写信息
      isNew: "",
      hostUserId: "",
    };
  },
  created() {
    // query中带有token表示从小程序登录跳转过来的。
    if (this.$route.query.JWT) {
      this.$localStore.set("isLogin", true);
      this.$localStore.set("JWT", this.$route.query.JWT);
    }
    this.meetingId = parseInt(this.$route.query.meetingId) || "";
    this.userId = parseInt(this.$route.query.userId) || "";
    this.backStatus = parseInt(this.$route.query.backStatus) || "";
    this.isNew = parseInt(this.$route.query.isNew) || "";
    this.hostUserId = parseInt(this.$route.query.hostUserId) || "";

    // 调用获取规模列表方法
    this.getScale();
    // 调用获取行业列表方法
    this.getIndustry();
    // 调用获取领域列表方法
    this.getfieldList();
    // 调用获取用户信息方法
    this.getUserInfo();
    // 获取type
    this.type = this.$route.query.type;
  },
  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      if (this.showHeight > document.documentElement.clientHeight) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    };
  },
  methods: {
    // 追加文字按钮
    addText() {
      this.introShow = true;
    },
    // 追加文字按钮弹出层确认
    introConfirm() {
      this.description.push({ text: this.introDialogText, type: "0" });
      this.introDialogText = "";
    },
    // 子组件方法
    changeCate(val) {
      this.description.forEach((item, _index) => {
        if (_index == val.index) {
          item.text = val.val;
        }
        this.$set(this.description, _index, item);
      });
    },
    deleteIntroText(index) {
      this.description.splice(index, 1);
    },
    deleteIntroImg(index) {
      this.description.splice(index, 1);
    },
    // 规模确定按钮
    onScaleConfirm(value, index) {
      this.scaleText = value;
      this.scaleId = this.scaleIds[index];
      // this.scaleDefaultIndex = index;
      this.scaleIsShow = false;
    },
    // 行业确认按钮
    onIndustryConfirm(value, index) {
      this.industryText = value;
      this.industryId = this.industryIds[index];
      // this.industryDefaultIndex = index;
      this.industryIsShow = false;
    },
    // 获取规模列表
    async getScale() {
      const resp = await this.$API.get("scale").then((resp) => resp);
      if (resp.code == 1000) {
        resp.data.forEach((item) => {
          this.scaleList.push(item.name);
          this.scaleIds.push(item.id);
        });
      }
    },
    // 获取行业列表
    async getIndustry() {
      const resp = await this.$API.get("industry").then((resp) => resp);
      if (resp.code == 1000) {
        resp.data.forEach((item) => {
          this.industryList.push(item.name);
          this.industryIds.push(item.id);
        });
      }
    },
    // 获取领域列表
    async getfieldList() {
      const resp = await this.$API.get("fieldList").then((resp) => resp);
      if (resp.code == 1000) {
        resp.data.forEach((item) => {
          item.active = false;
        });
        this.fieldList = resp.data;
      }
    },
    // 领域多选点击
    btnActive(index) {
      this.fieldList[index].active = !this.fieldList[index].active;
    },
    // 点击保存事件
    async savePerfect() {
      if (!this.defaultAvatar) {
        this.$toast("请上传头像");
        return;
      }
      if (!this.nameText) {
        this.$toast("请填写真实姓名");
        return;
      }
      if (!this.companyText) {
        this.$toast("请填写所属公司");
        return;
      }

      if (!this.industryId) {
        this.$toast("请选择所属行业");
        return;
      }
      if (!this.scaleId) {
        this.$toast("请选择公司规模");
        return;
      }
      if (!this.positionText) {
        this.$toast("请输入担任职务");
        return;
      }
      let ids = [];
      this.fieldList.forEach((item) => {
        if (item.active) {
          ids.push(item.id);
        }
      });

      let descriptionList = [];
      this.description.forEach((item) => {
        if (item.type == "0" && item.text != "") {
          descriptionList.push({
            type: "0",
            text: item.text,
          });
        } else if (item.image != "") {
          descriptionList.push({ type: "1", image: `${item.image}` });
        }
      });
      if (!ids.length) {
        this.$toast("请选择擅长领域");
        return;
      }

      const data = {
        avatar: this.defaultAvatar,
        name: this.nameText,
        company: this.companyText,
        industryId: this.industryId,
        scaleId: this.scaleId,
        job: this.positionText,
        fields: ids,
        style: this.styleText || "",
        description: JSON.stringify(descriptionList),
      };

      const resp = await this.$API.post("userEdit", data).then((resp) => resp);

      if (resp.code == 1000) {
        this.$toast("信息保存成功");
        if (this.backStatus == 3) {
          this.$goReplace("invite", {
            meetingId: this.meetingId,
            userId: this.userId,
            hostUserId: this.hostUserId,
          });
        } else {
          if (this.isNew) {
            this.$goReplace("home");
          } else {
            this.$goReplace("me");
          }
        }
      }
    },
    // 获取用户信息
    async getUserInfo() {
      const resp = await this.$API.post("userInfo").then((resp) => resp);
      if (resp.code == 1000) {
        this.defaultAvatar = resp.data.info.avatar;
        this.nameText = resp.data.info.name;
        this.companyText = resp.data.info.company;
        if (resp.data.info.scale) {
          this.scaleText = resp.data.info.scale.name || null;
          this.scaleId = resp.data.info.scale.id;
        }
        if (resp.data.info.industry) {
          this.industryText = resp.data.info.industry.name || null;
          this.industryId = resp.data.info.industry.id;
        }
        this.positionText = resp.data.info.job;
        // 判断领域是否选中
        resp.data.info.field.forEach((item_) => {
          this.fieldList.forEach((item) => {
            if (item.id == item_.id) {
              item.active = true;
            }
          });
        });
        if (resp.data.info.type == 1) {
          this.styleText = resp.data.hostUser.style;
        }
        this.description = resp.data.info.description;
      }
    },
    /**
     * 轻提示
     */
    toastFun(msg) {
      this.toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: msg,
      });
    },

    afterRead(file) {
      uploadImg(file, (url) => {
        this.getUploadContent(url)
      })
    },

    getUploadContent(url) {
      this.defaultAvatar = url;
    },

    getUploadContentAdd(url) {
      this.description.push({ image: url, type: "1" });
    },

    // 追加图片
    afterReadAdd(file) {
      uploadImg(file, (url) => {
        this.getUploadContentAdd(url)
      })
    },
  },
};
</script>

<style lang="less" scoped>
.perfect-big {
  width: 7.5rem;
  .portrait-box {
    height: 1.16rem;
    width: 7.14rem;
    margin-left: 0.36rem;
    border-bottom: 0.01rem solid #eeeeee;
    .portrait {
      width: 0.76rem;
      height: 0.76rem;
      position: absolute;
      border-radius: 50%;
      right: 0;
      margin-right: 0.78rem;
      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .general-box {
    height: 0.88rem;
    width: 7.14rem;
    margin-left: 0.36rem;
    .input-box {
      height: 0.3rem;
      width: 5rem;
      position: absolute;
      right: 0;
      margin-right: 0.1rem;
    }
    .choice-box {
      height: 0.87rem;
      width: 4.5rem;
      position: absolute;
      right: 0;
      margin-right: 0.7rem;
    }
  }
  .general-box-bottom {
    border-bottom: 0.01rem solid #eeeeee;
  }
  .bg-color {
    height: 0.2rem;
    background-color: #f6f6f6;
  }
  .field-box {
    .title-left {
      margin-left: 0.36rem;
      margin-top: 0.42rem;
    }
    .doux {
      margin-top: 0.45rem;
      margin-left: 0.08rem;
    }
    .field-list-box {
      margin-top: 0.18rem;
      margin-left: 0.36rem;
      .field-list-un {
        background: #f6f6f6;
        border-radius: 0.08rem;
        min-width: 1.56rem;
        padding: 0 0.2rem;
        height: 0.56rem;
        margin-right: 0.18rem;
        margin-top: 0.18rem;
      }
      .field-list-on {
        color: #cb9025;
        background: #fff;
        border: 1px solid #cb9025;
      }
    }
  }
  .arrow-box {
    position: absolute;
    right: 0;
    margin-right: 0.36rem;
  }
  .intro-box {
    margin: 0.36rem 0.36rem 0 0.36rem;
    margin-bottom: 0.2rem;
    .detail-intro {
      margin-top: 0.36rem;
    }
  }
  .mauto-box {
    padding: 0.3rem 0;
    width: 7.5rem;
    bottom: 0;
    position: fixed;
  }
  .host-mauto-box {
    height: 1.2rem;
    width: 7.5rem;
    bottom: 0;
    position: fixed;
    background: #fff;
  }
}
</style>
